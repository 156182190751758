import React from "react";
import Hero from "./Hero";
import Hero_bg from "../assets/img/hero_bg.jpg";
import Section from "./Section";
import Card from "./Card";
import ImageTextSection from "./ImageTextSection";
import webAgency_image from "../assets/img/web_agency.jpg";
import mohs_image from "../assets/img/software_ristorazione_ospedaliera.jpg";
import misuratoriFiscaliRT_image from "../assets/img/misuratori_fiscali.png";
import t_noleggio_image from "../assets/img/t_noleggio.png";
import t_toner_image from "../assets/img/t_toner.png";
import t_riparo_image from "../assets/img/t_riparo.png";
import safereport_image from "../assets/img/safereport.jpg";

export default function Body(props) {
  return (
    <div className="overflow-x-hidden">
      <Hero
        title="SPLIT ® GL S.r.l.s."
        content="Servizi e Soluzioni per l'informatica."
        image={Hero_bg}
      />

      <Section secondary>
        <div className="container mx-auto py-12 lg:py-14 xl:py-20">
          <div className="text-center mx-auto lg:text-lg" >
            <h3 className="text-2xl justify-center text-center pb-8">SPLIT ® - Servizi e Soluzioni per l'informatica</h3>
            <p className="pb-10 mx-auto">
              SPLIT nasce nel 2012 dalla partnership tra il Dr. Orazio Sciuto, fondatore della rinomata Informatica Progetti srl, e il giovanissimo Giuseppe Catanzaro,.<br />
              Negli anni ha continuato a rafforzare la propria presenza nelle province di Catania, Messina, Ragusa e Siracusa, crescendo come punto di riferimento nel mercato locale.<br />
              Con il passaggio del testimone al nuovo gruppo societario GL S.r.l.s., abbiamo varcato confini più ampi, posizionandoci come leader in tutto il Centro-Sud Italia.
            </p>
            <p className="pb-10 mx-auto">
              Oggi, vantiamo una squadra di tecnici e collaboratori certificati, pronti a garantire tempi rapidi di intervento, risoluzione dei problemi e recupero da situazioni critiche.<br />
              Operiamo quasi esclusivamente per un parco clienti consolidato, accordi precisi e protocolli standardizzati.<br />
            </p>
            <p className="pb-10 mx-auto">
              Il nucleo dell'azienda è la commercializzazione, manutenzione e assistenza di una Suite di Software per la gestione completa della Ristorazione Collettiva.<br />
              Con orgoglio, possiamo affermare che questi software sono attualmente utilizzati in oltre 20 centri cottura ospedalieri sparsi nel Centro-Sud ed Isole.
            </p>
          </div>
        </div>
      </Section>

      <Section >
        <div className="container mx-auto py-12 lg:py-12">
          <h2 className="text-3xl justify-center text-center pb-8">
            🚀 Esplora le Nostre Divisioni 🚀
          </h2>
          <p className="prose-xl w-full rich-text-block text-center pb-10 mx-auto">
            Scopri come SPLIT® può portare la tua azienda al livello successivo. <br />
            Esplora le nostre divisioni specializzate e inizia il tuo viaggio verso l'eccellenza digitale oggi stesso!
          </p>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 justify-start">
            <a href="https://www.splitweb.it/agency/" title="SPLIT WebAgency Siti Web E-Commerce Giarre Catania">
              <Card
              align="left"
              image={webAgency_image}
              title="🌐 WebAgency"
              content=" Se desideri un sito web che catturi l'essenza della tua azienda e attiri nuovi clienti, la nostra divisione WebAgency è pronta a realizzare il tuo sogno online.
              Visita il nostro sito dedicato per saperne di più.
              "
              />
            </a>
            <a href="https://www.cucinaospedale.it/" title="MOHS Software Ristorazione Ospedaliera, Prenotazione Pasti Ospedale">
              <Card
                align="left"
                image={mohs_image}
                title="📂 Software Ristorazione Ospedaliera"
                content=" L'evoluzione nella gestione ospedaliera.
                          Una Suite avanzata di software e strumenti per la gestione della ristorazione ospedaliera, semplificando le operazioni quotidiane e migliorando l'efficienza. Scopri di più sul nostro sito dedicato.
                        "
              />
            </a>
            <a href="#" title="Misuratori Fiscali RT, Registratori di Cassa Telematici Giarre Catania">
              <Card
                align="left"
                image={misuratoriFiscaliRT_image}
                title="🏛️ Registratori di Cassa Telematici"
                content=" La divisione Registratori di Cassa RT offre soluzioni telematiche per tutti i punti vendita.
                          Ci occupiamo noi della vendita, dell'assistenza e delle verifiche periodiche biennali.
                          Scopri di più sul nostro sito dedicato."
              />
            </a>
            <a href="#" title="Ingrosso e Dettaglio di Toner Compatibili">
              <Card
                align="left"
                image={t_toner_image}
                title="🛍️ T-Toner"
                content=" Dalla vendita al dettaglio e all'ingrosso, ai consumabili originali e compatibili per marchi prestigiosi.
                          Serviamo anche le pubbliche amministrazioni.
                          Per approfondire, visita il nostro sito dedicato."
              />
            </a>
            <a href="https://www.splitweb.it/noleggio-stampanti/" title="Noleggio Operativo Stampanti Multifunzioni Fotocopiatori Sicilia">
              <Card
                align="left"
                image={t_noleggio_image}
                title="🖨️ T-Noleggio"
                content=" Noleggio Operativo All-Inclusive. Dai vita all'efficienza con il noleggio di stampanti multifunzioni e fotocopiatori.
                          Servizio completo con assistenza, toner, materiali e smaltimento. Scopri di più sul nostro sito dedicato."
              />
            </a>
            <a href="" title="Assistenza Tecnica Informatica, Riparazione PC e Server">
              <Card
                align="left"
                image={t_riparo_image}
                title="🛠️ T-Riparo"
                content=" Riparazioni rapide ed esperte. Dalla riparazione di smartphone, display e console, ai servizi per PC Desktop e Notebook.
                          La divisione T-Riparo è qui per ridare vita ai tuoi dispositivi.
                          Per dettagli completi, visita il nostro sito dedicato."
              />
            </a>
            <a href="https://safereport.it/" title="SafeReport - Whistleblowing Anonimo Aziende e Pubblica Amministrazione">
              <Card
                align="left"
                image={safereport_image}
                title="🛍️ SafeReport - Whistleblowing"
                content=" Piattaforma sicura ed anonima di whistleblowing per le pubbliche amministrazioni e aziende private con più di 50 dipendenti.
                          In conformità della Direttiva UE 2019/1937 e del D.Lgs 24/2023.
                          La soluzione chiavi in mano per tutti gli adempimenti della normativa."
              />
            </a>

          </div>
        </div>
      </Section>

      {/* <Section>
        <div className="container mx-auto py-12 lg:py-12">
          <ImageTextSection
            reverse
            image={Somministrazione}
            title="La somministrazione dei pasti come parte integrante della terapia clinica"
            content=" La nutrizione clinica è una pratica medica che mira a mantenere o migliorare lo stato di nutrizione di un paziente attraverso una serie di interventi preventivi, diagnostici e terapeutici. Tuttavia, la malnutrizione è spesso un rischio per i pazienti ricoverati in ospedale, poiché la loro condizione di salute può compromettere il loro stato nutrizionale. Questo a sua volta può influenzare l'andamento della malattia e la durata dell'ospedalizzazione.
                      Per garantire una nutrizione adeguata ai pazienti, le strutture di dietetica e nutrizione hanno il compito di fornire un intervento nutrizionale personalizzato, che può essere di varia intensità a seconda delle esigenze del paziente. Questo intervento nutrizionale può prevenire e curare situazioni patologiche legate allo stato di nutrizione del paziente.
                      La sorveglianza nutrizionale è un'attività importante per garantire che il paziente stia mangiando adeguatamente e per individuare eventuali rischi di malnutrizione, come problemi dell'apparato digerente o difficoltà masticatorie.
                      Per supportare l'attività di ristorazione ospedaliera, MOHS è una suite di software che copre tutti gli aspetti della nutrizione, compresi quelli culturali e religiosi. Questo sistema di gestione del cibo è connesso con i sistemi aziendali di gestione dei ricoveri, ha una banca dati degli alimenti e gestisce i menu e le ricette, riducendo gli sprechi e ottimizzando il processo produttivo e di consegna. MOHS è uno strumento ideale per le Unità di Nutrizione Clinica delle strutture sanitarie ed assistenziali di qualsiasi dimensione.
                    "
          />
          <ImageTextSection
            image={VassoioPersonalizzato}
            title="Vassoio Personalizzato a letto del degente"
            content=" La gestione del servizio di ristorazione ospedaliera è una sfida complessa che richiede attenzione ai dettagli e una gestione efficiente. Con la nostra suite di software, siamo in grado di offrire una soluzione completa per la gestione della ristorazione personalizzata per pazienti ricoverati.
                      La nostra suite di software consente la creazione di diete personalizzate, l'ordinazione di pasti attraverso tablet e la gestione della produzione di cibi in modo efficiente e preciso. Ci sono circa 25 tipologie di diete standard, ma è sempre possibile richiedere al servizio interno la produzione di diete personalizzate in base a specifiche esigenze.
                      L'uso dei vassoi personalizzati a letto del degente consente la distribuzione rapida e facile dei pasti, mantenendo la giusta temperatura e i sapori dei cibi. I pasti del giorno cambiano ogni giorno con una rotazione settimanale o bisettimanale e alcune scelte fisse disponibili tutti i giorni.
                      La nostra suite di software è stata progettata per essere facile da usare e personalizzabile per soddisfare le esigenze specifiche di ogni ospedale. Offriamo un servizio di assistenza dedicato per garantire un'esperienza senza problemi.
                      La nostra suite di software rappresenta una soluzione completa e innovativa per la gestione della ristorazione personalizzata per pazienti ricoverati. Grazie alla nostra tecnologia avanzata e al nostro team dedicato, siamo in grado di offrire un servizio di ristorazione di alta qualità che soddisfa le esigenze nutrizionali e le preferenze dei pazienti, migliorando la loro esperienza durante il periodo di degenza.
                    "
          />
          <ImageTextSection
            reverse
            image={DietaPersonalizzata}
            title="Le Diete Speciali di tipo Sanitario"
            content=" Grazie alla nostra suite di software è possibile semplificare e rendere più efficiente la gestione della ristorazione ospedaliera con tutte le sue diete speciali di tipo sanitario.
                      Il software viene utilizzato per creare e gestire i menu per le diverse diete speciali, permettendo di programmare i pasti e garantire che ogni paziente riceva un pasto adatto alle proprie esigenze nutrizionali e cliniche. 
                      Si può monitorare il consumo del pasto per ogni paziente, garantendo che riceva un apporto nutrizionale e bromatologico adeguato e tenere traccia delle preferenze alimentari e delle allergie dei pazienti, assicurando che i pasti vengano preparati con ingredienti sicuri e accettabili.
                      Si semplifica la gestione delle ricette, permettendo di modificare le ricette esistenti o crearne di nuove, in base alle esigenze dei pazienti. In questo modo, la preparazione dei pasti diventa più efficiente e personalizzata riducendo gli sprechi alimentari.
                      Si riduce il rischio di errori nella preparazione e nella consegna dei pasti, migliorando l'esperienza dei pazienti e aumentando la sicurezza alimentare.
                    "
          />
        </div>
      </Section> */}

      <Section secondary>
        <div className="container mx-auto pt-12 lg:pt-14 xl:pt-20 testimonials-carousel pb-12 lg:pb-14 xl:pb-20">
          <div className="flex h-full my-auto">
            <div className="prose max-w-5xl flex flex-col gap-4 justify-center text-center mx-auto items-center">
              <h2 className="text-3xl">🌟 Cosa Dicono di Noi 🌟</h2>
              <h3 className="text-xl lg:text-2xl xl:text-h3 !font-medium">
                SPLIT ® ha trasformato la nostra presenza online e ha reso il nostro brand riconoscibile all'istante.
              </h3>
              <p className="text-sm lg:text-body xl:text-2xl">- Antonio Rossi, CEO di Azienda XYZ</p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container mx-auto py-12 lg:py-14 xl:py-20">
          <div className="flex flex-col w-full gap-10 lg:gap-20 lg:flex-row">
            <div className="prose w-full lg:w-1/2 lg:mt-10">
              <h2 className="text-body lg:text-4xl font-medium mb-2">
                📞 Contattaci Oggi Stesso! 📞
              </h2>
              <p className="lg:leading-6">
                Hai domande o vuoi saperne di più sulle nostre divisioni e servizi? <br />
                Non esitare a contattarci. Siamo a tua disposizione per guidarti verso il successo.
              </p>
              <p className="lg:leading-6">
                Indirizzo: Viale Don Minzoni, 46, 95014 Giarre (CT) <br />
                Telefono: 095 7791192 <br />
                Email: info@splitweb.it
              </p>
            </div>
            <div className="w-full lg:w-1/2">
              <form>
                <div className="flex flex-col lg:flex-row gap-4 mb-4">
                  <div className="flex-1">
                    <label className="mb-1 text-label">Nome</label>
                    <input type="text" className="form-input mt-1 block w-full rounded-md" />
                  </div>
                  <div className="flex-1">
                    <label className="mb-1 text-label">E-mail</label>
                    <input type="text" className="form-input mt-1 block w-full rounded-md" />
                  </div>
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-label">Messaggio</label>
                  <textarea className="form-textarea mt-1 block w-full rounded-md" placeholder="" rows="5"></textarea>
                </div>
                <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                  <div className="text-xs max-w-sm text-gray-500">This site is protected by reCAPTCHA and the Google<a target="_blank" className="font-bold" href="https://policies.google.com/privacy"> Privacy Policy</a> and<a target="_blank" className="font-bold" style={{color: '#111827'}} href="https://policies.google.com/terms"> Terms of Service</a> apply.</div>
                  <button type="submit" className="bg-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Invia
                  </button>
                </div>
                <div className="invisible">
                  <div>
                    <div className="grecaptcha-badge" style={{width: '256px', height: '60px', boxShadow: 'gray 0px 0px 5px'}}>
                      <div className="grecaptcha-logo">
                        {/* <iframe title="reCAPTCHA" src="./Home Page_files/anchor.html" width="256" height="60" role="presentation" name="a-6gky8ia55oqt" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe> */}
                      </div>
                      <div className="grecaptcha-error"></div>
                      <textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response" style={{width: '250px', height: '40px', border: '1px solid rgb(193, 193, 193)', margin: '10px 25px', padding: '0px', resize: 'none', display: 'none'}}></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
