import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import Timing from 'components/Timing.js';
import Header from 'components/Header.js';

export default function Caratteristiche() {
  return (
    <>
      <Header />
      <h1>Caratteristiche</h1>
      <Footer />
    </>
  );
}
