import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import Timing from 'components/Timing.js';
import Header from 'components/Header.js';
import Section from 'components/Section.js';

export default function Chisiamo() {
  return (
    <>
      <Header />

      <Section secondary>
        <div className="container mx-auto py-12 lg:py-14 xl:py-20">
          <div className="text-center mx-auto lg:text-lg" >
            <h3 className="text-2xl justify-center text-center pb-8">SPLIT ® - Servizi e Soluzioni per l'informatica</h3>
            <p className="pb-10 mx-auto">
              SPLIT nasce nel 2012 dalla partnership tra il Dr. Orazio Sciuto, fondatore della rinomata Informatica Progetti srl, e il giovanissimo Giuseppe Catanzaro,.<br />
              Negli anni ha continuato a rafforzare la propria presenza nelle province di Catania, Messina, Ragusa e Siracusa, crescendo come punto di riferimento nel mercato locale.<br />
              Con il passaggio del testimone al nuovo gruppo societario GL S.r.l.s., abbiamo varcato confini più ampi, posizionandoci come leader in tutto il Centro-Sud Italia.
            </p>
            <p className="pb-10 mx-auto">
              Oggi, vantiamo una squadra di tecnici e collaboratori certificati, pronti a garantire tempi rapidi di intervento, risoluzione dei problemi e recupero da situazioni critiche.<br />
              Operiamo quasi esclusivamente per un parco clienti consolidato, accordi precisi e protocolli standardizzati.<br />
            </p>
            <p className="pb-10 mx-auto">
              Il nucleo dell'azienda è la commercializzazione, manutenzione e assistenza di una Suite di Software per la gestione completa della Ristorazione Collettiva.<br />
              Con orgoglio, possiamo affermare che questi software sono attualmente utilizzati in oltre 20 centri cottura ospedalieri sparsi nel Centro-Sud ed Isole.
            </p>
          </div>
        </div>
      </Section>

      {/* <Section>
        <div className="container mt-3 mx-auto py-12 lg:py-14 xl:py-20">
          <div className="prose rich-text-block max-w-5xl text-left" >
            <h3>SPLIT ® - Un marchio di GL S.r.l.s.</h3>
            <p>...</p>
          </div>
        </div>
      </Section> */}
      <Footer />
    </>
  );
}
