import React from 'react';
import "../footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import LogoSPLIT from "../assets/img/logo.svg";
import LogoMOHS from "../assets/img/logo_mohs.png";
import FondiRegione from "../assets/img/fondi_po_fesr_2014-2020.png";
import SGS from '../assets/img/SGS_ISO9001_2015.png';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="flex-1 bg-blue text-white">
      <div className="flex flex-col lg:flex-row items-center gap-12 lg:gap-3 container mx-auto py-12 lg:py-14 xl:py-20">
        <div className="flex-1 w-full lg:w-auto">
          <h6 className='uppercase font-bold mb-4'>SPLIT ® GL S.r.l.s.</h6>
          <img className="h-20 md:h-50" src={LogoSPLIT} alt="SPLIT Giarre Catania Sicilia" title="MOHS - Meals Organizer for Hospital System" />
          <p>

          </p>
        </div>
        <div className="flex-1 w-full lg:w-auto">
          <img className="h-20 md:h-50" src={LogoMOHS} alt="MOHS Software Ristorazione Ospedaliera" title="MOHS - Meals Organizer for Hospital System" />
          <img className="h-20 md:h-20" src={FondiRegione} alt="Software Ristorazione Ospedaliera" title="MOHS - Meals Organizer for Hospital System" />
        </div>
        <div className="flex-1 w-full flex flex-col gap-12 lg:gap-6 justify-end">
          <div className="flex flex-col lg:flex-row items-start lg:items-center lg:justify-end gap-12 lg:gap-14 w-full lg:w-auto max-w-[100vw]">
            <ul className="flex flex-col lg:flex-wrap lg:flex-row lg:items-center gap-6" style={{color: 'rgb(255, 255, 255)'}}>
              <li className="border-b-2" style={{borderColor: 'rgb(255, 255, 255)'}}>
                <Link to="/" className="block py-1.5 font-light">Home</Link>
              </li>
              <li className="border-b-2" style={{borderColor: 'transparent'}}>
                <a href="//www.splitweb.it/agency" className="block py-1.5 font-light">Web Agency</a>
              </li>
              <li className="border-b-2" style={{borderColor: 'transparent'}}>
                <a href="//www.splitweb.it/noleggio-stampanti" className="block py-1.5 font-light">Noleggio Stampanti</a>
              </li>
              <li className="border-b-2" style={{borderColor: 'transparent'}}>
                <a href="//www.cucinaospedale.it" className="block py-1.5 font-light">Software Ristorazione Ospedaliera</a>
              </li>
            </ul>
            <ul className="flex items-center gap-x-7 lg:gap-x-4 gap-y-2 flex-wrap">
              <li>
                <a target="_blank" href="https://www.facebook.com/SplitSrl/">
                  <FontAwesomeIcon icon={brands('facebook')} size='lg' />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.linkedin.com/company/splitgroup/">
                  <FontAwesomeIcon icon={brands('linkedin')} size='lg' />
                </a>
              </li>
            </ul>
          </div>
          <span className="text-base lg:text-right">Developed By <a target="_blank" className="underline" style={{color: 'rgb(255, 255, 255)'}} href="//www.splitweb.it">SPLIT ® | GL S.r.l.s.</a></span>
        </div>
      </div>
      <div className="flex flex-row items-center text-center p-4 mx-auto" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
        <div className='basis-1/4'>
          <img className="float-right" src={SGS} width="100" alt="SGS ISO 9001:2015"/>
        </div>
        <div className='basis-1/2'>
          <p>GL S.r.l.s. P.IVA 05362310871 | © 2023 All Right Reserved</p>
          <a className="font-bold" href="https://www.splitweb.it/"> SPLIT ® Web Agency Giarre Catania</a> - <Link className="" to="/Privacy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
}
