import React from 'react';
import CookieOption from './CookieOption';
import bannerStyle from './bannerStyle';

export default (props = {}) => {
  const {
    styles = {},
    necessaryOptionText = 'Necessary',
    preferencesOptionText = 'Preferences',
    statisticsOptionText = 'Statistics',
    marketingOptionText = 'Marketing',
    showPreferencesOption = true,
    showStatisticsOption = true,
    showMarketingOption = true,
    preferencesDefaultChecked = false,
    statisticsDefaultChecked = false,
    marketingDefaultChecked = false,
    onTogglePreferencesCookies = () => {},
    onToggleStatisticsCookies = () => {},
    onToggleMarketingCookies = () => {},
    statisticsDescription = 'I cookie statistici aiutano i proprietari del sito web a capire come i visitatori interagiscono con i siti raccogliendo e trasmettendo informazioni in forma anonima.',
    preferencesDescription = 'I cookie di preferenza consentono al sito web di memorizzare informazioni che ne influenzano il comportamento o l\'aspetto, quali la lingua preferita o la località nella quale ti trovi.',
    marketingDescription = 'I cookie di marketing vengono utilizzati per tracciare i visitatori sui siti web. La finalità è quella di presentare annunci pubblicitari che siano rilevanti e coinvolgenti per il singolo utente e quindi di maggior valore per editori e inserzionisti di terze parti.',
    necessaryDescription = 'I Cookies Tecnici, sono essenziali affinché il sito web funzioni correttamente. Questa categoria inlude solamente Cookie che assicurano le funzionalità di base e di sicurezza del sito. Questi Cookie non memorizzano nessuna informazione personale.'
  } = props;

  const {
    selectPane: selectPaneStyle,
    optionWrapper: optionWrapperStyle,
    optionLabel: optionLabelStyle,
    optionChevron: optionChevronStyle,
    optionDescription: optionDescriptionStyle,
    checkbox: checkboxStyle,
  } = { ...bannerStyle, ...styles };

  const cookieOptionStyle = { optionWrapperStyle, optionLabelStyle, optionChevronStyle, checkboxStyle };

  return (
    <div className="react-cookie-law-select-pane" style={selectPaneStyle}>
      <CookieOption
        id="check-required-cookies"
        text={necessaryOptionText}
        styles={cookieOptionStyle}
        descriptionStyle={optionDescriptionStyle}
        description={necessaryDescription}
        disabled={true}
        checked={true}
      />

      {
        showPreferencesOption && (
          <CookieOption
            id="check-preferences-cookies"
            text={preferencesOptionText}
            styles={cookieOptionStyle}
            descriptionStyle={optionDescriptionStyle}
            description={preferencesDescription}
            checked={preferencesDefaultChecked}
            onChange={onTogglePreferencesCookies}
          />
        )
      }

      {
        showStatisticsOption && (
          <CookieOption
            id="check-statistics-cookies"
            text={statisticsOptionText}
            styles={cookieOptionStyle}
            description={statisticsDescription}
            descriptionStyle={optionDescriptionStyle}
            checked={statisticsDefaultChecked}
            onChange={onToggleStatisticsCookies}
          />
        )
      }

      {
        showMarketingOption && (
          <CookieOption
            id="check-marketing-cookies"
            text={marketingOptionText}
            styles={cookieOptionStyle}
            description={marketingDescription}
            descriptionStyle={optionDescriptionStyle}
            checked={marketingDefaultChecked}
            onChange={onToggleMarketingCookies}
          />
        )
      }
    </div>
  );
};
