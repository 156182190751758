import React from 'react';

class CookieOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked,
      hideDescription: true
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange() {
    const { onChange = () => {} } = this.props;
    const checked = this.state.checked;
    const newValue = !checked;

    this.setState({ checked: newValue });
    onChange(newValue);
  }

  render() {
    const {
      id = '',
      text = '',
      styles = {},
      disabled = false,
      description = '',
      descriptionStyle = () => {},
    } = this.props;

    const isDisabled = disabled ? { disabled } : {};
    const checked = this.state.checked;

    const {
      optionWrapperStyle,
      optionLabelStyle,
      optionChevronStyle,
      checkboxStyle,
    } = styles;

    const toggleDescription = () => {
        if (this.state.hideDescription) {
          this.setState( { hideDescription: false });
        }
        else {
          this.setState( { hideDescription: true });
        }
    };

    let arrow = <>&#8964;</>;

    if (this.state.hideDescription) {
        arrow =  <>&#8964;</>;
    }
    else {
        arrow = <>&#8963;</>;
    }

    // return (

    //         <div className="react-cookie-law-option-wrapper" style={optionWrapperStyle}>

    //           <input
    //             type="checkbox"
    //             id={id}
    //             className="react-cookie-law-option-checkbox"
    //             style={checkboxStyle}
    //             checked={checked || false}
    //             onChange={this.handleOnChange}
    //             {...isDisabled}
    //           />

    //           <label className="react-cookie-law-option-label" htmlFor={id} style={optionLabelStyle}>{text}</label>

    //           <span className="react-cookie-law-option-chevron" style={optionChevronStyle} onClick={toggleDescription}>
    //             {arrow}
    //           </span>

    //           <div className="react-cookie-law-description" style={descriptionStyle(this.state.hideDescription)}>
    //             {description}
    //           </div>

    //         </div>
    // );

    return (
      <div className='react-cookie-law-option-wrapper' style={optionWrapperStyle}>
        <div className="flex"  >
          <label className="react-cookie-law-option-label inline-flex relative items-center cursor-pointer  justify-between">
            <input 
              type="checkbox" 
              className="react-cookie-law-option-checkbox sr-only peer"
              style={checkboxStyle}
              htmlFor={id}
              checked={checked || false}
              onChange={this.handleOnChange}
              {...isDisabled}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
          <label data-accordion="collapse"
            id={`accordion-collapse-heading-${id}`}
            onClick={toggleDescription}
            className="inline-flex relative items-center cursor-pointer w-full justify-between" >
            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{text}</span>
            <svg 
              data-accordion-icon 
              className="w-6 h-6 l-0 rotate-180 shrink-0" 
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
            ><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </label>
        </div>
          <div 
            id={`accordion-collapse-body-${id}`} 
            aria-labelledby={`accordion-collapse-heading-${id}`}
          >
            <div
              className="p-15 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900"
              style={descriptionStyle(this.state.hideDescription)}
            >
              {description}
            </div>
          </div>
        </div>  
    );
  }
}

export default CookieOption;
