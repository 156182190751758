import React from 'react';

export default function Card(props) {
  return (
    <div className="block-list-item">
      <div className="shadow rounded border border-gray-300 mb-6">
        <img className="w-full aspect-video object-cover object-center" alt={props.title}
          src={props.image}
        />
      </div>
      <div className='md:prose-md' style={{textAlign: props.align}}>
        <h2 className="font-bold mb-2 dark:text-white">
          {props.title}
        </h2>
        <p className="">{props.content}</p>
        {/* <a
          className="button primary lg mt-4"
          target="_self"
          href={props.action.link}
          style={{
            background: 'rgb(0, 75, 143)',
            borderRadius: '8px',
            color: 'rgb(255, 255, 255)',
            border: '2px solid rgb(0, 75, 143)'}}
          >{props.action.label}</a> */}
      </div>
    </div>
  );
}
