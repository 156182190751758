export default {
  dialog: {
    position: 'fixed',
    // top: 'initial',
    left: '0',
    // right: '10px',
    bottom: '0px',
    // transform: 'initial',
    zIndex: '100000',
    backgroundColor: '#f8f7f7',
    padding: '15px',
    maxHeight: 'calc(100vh - 20px)',
    height: 'auto',
    minWidth: '70vw',
    // maxWidth: '90vw',
    width: '100%',
    boxShadow: '0 -1px 10px rgb(172 171 171 / 30%)',
    // borderRadius: '1em'
  },
  container: {
    // maxWidth: '960px',
    verticalAlign: 'middle',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
  },
  message: {
    minHeight: '32px',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '130%',
    padding: '10px 0',
    color: '#000000',
  },
  policy: {
    fontSize: '.8rem',
    marginLeft: '10px',
    color: '#000000',
    textDecoration: 'underline',
    display: 'flex'
  },
  imprint: {
    fontSize: '.8rem',
    marginLeft: '10px',
    color: '#000000',
    textDecoration: 'underline',
    display: 'flex'
  },
  selectPane: {
    display: 'block',
    padding: '3px 0px 10px 0px',
  },
  optionWrapper: {
    display: 'block',
    position: 'relative',
    margin: '7px',
    float: 'left',
    width: '100%',
    backgroundColor: 'rgba(239, 239, 239, 0.5)',
    padding: '10px'
  },
  optionLabel: {
    height: 'auto',
    width: 'auto',
    minHeight: '14px',
    fontSize: '1.2rem',
    color: '#c6c6c6',
    display: 'inline-block',
    position: 'relative',
    top: '0',
    left: '0',
    zIndex: '1',
    cursor: 'default',
    verticalAlign: 'top',
    marginRight: '.5rem'
  },
  optionDescription: hide => ({
    display: hide ? 'none' : 'block',
    fontSize: '1rem',
    lineHeight: '1.4',
    padding: '10px 20px',
    color: '#000'
  }),
  optionChevron: {
    position: 'absolute',
    top: '1px',
    right: '15px',
    fontSize: '2rem',
    cursor: 'pointer'
  },
  checkbox: {
    position: 'absolute',
    top: '14px',
    right: '40px',
    width: '14px',
    height: '14px',
    zIndex: '2',
    cursor: 'pointer',
  },
  buttonWrapper: isSmallScreen => ({
    float: 'right',
    flexDirection: isSmallScreen ? 'column' : 'row',
    display: 'flex',
    width: '100%'
  }),
  linkContainer: isSmallerScreen => ({
    display: 'flex',
    flexDirection: isSmallerScreen ? 'column' : 'row',
    width: '100%'
  }),
  button: {
    display: 'inline-block',
    alignSelf: 'center',
    backgroundColor: '#000000',
    minWidth: '80px',
    width: '90%',
    color: '#ffffff',
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: '400',
    margin: '10px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  }
};
