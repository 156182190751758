import React, { useEffect } from "react";
import LogoWeb from "../assets/img/logo_web.png";
import Logo from "../assets/img/logo.svg";
import "../header.css";
import '../slide.css';

import { useState } from "react";
import { Link } from "react-router-dom";

export default function Header(props) {
  const [isOpen, setOpen] = useState(false);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    setLinks([
        {
            slug: '',
            title: 'Home',
            active: window.location.pathname === '/',
        },
        {
            slug: 'chi-siamo',
            title: 'Chi siamo',
            active: window.location.pathname.indexOf('/chi-siamo') !== -1,
        },
        // {
        //     slug: 'caratteristiche',
        //     title: 'Caratteristiche',
        //     active: window.location.pathname.indexOf('/caratteristiche') !== -1,
        // },
        // {
        //     slug: 'demo',
        //     title: 'Richiedi Demo',
        //     active: window.location.pathname.indexOf('/demo') !== -1,
        // },
    ])
  }, []);
  const handleBurgerClick = () => {
    setOpen(!isOpen);
  }

  return(
    <header>
      <nav className="fixed z-50 left-0 top-0 w-full shadow" style={{backgroundColor: 'rgb(0, 75, 143)', color: 'rgb(255, 255, 255)'}}>
        <div className="container flex items-center justify-between flex-wrap bg-teal py-6 px-4 md:px-0 mx-auto">
          <div className="flex items-center flex-no-shrink text-white">
            <Link to={'/'} title="Home">
                <img className="h-10 md:h-50" src={Logo} alt="Software Ristorazione Ospedaliera" title="MOHS - Meals Organizer for Hospital System" />
            </Link>
          </div>
          <div className="block lg:hidden">
            <button className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light" onClick={handleBurgerClick}>
              <svg className="h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
            </button>
          </div>
          <div className={'w-full flex-grow lg:mt-0 lg:flex lg:items-center lg:w-auto' + (isOpen ? ' block' : ' hidden')}>
            <div className="text-sm ml-auto">
              {links.map((link, idx) => (
                <Link to={'/' + link.slug} key={link.slug + idx} title={link.title} className={"block mt-8 lg:inline-block lg:mt-0 px-2 mx-2" + (link.active ? ' font-bold' : '')}>
                    {link.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
