import React from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Seo (props){
  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta property="og:type" content={props.type} />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta name="twitter:creator" content={props.author} />
        <meta name="twitter:card" content={props.type} />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.description} />
      </Helmet>
    </HelmetProvider>
  );
};
